import { Popover } from "@headlessui/react";
import { FC } from "react";
import { HexColorPicker } from "react-colorful";
import { twMerge } from "tailwind-merge";

type ColorInputProps = {
  value: string;
  disabled?: boolean;
  onChange: (value: string) => void;
};

const ColorInput: FC<ColorInputProps> = (props) => {
  const { value, disabled, onChange } = props;
  return (
    <Popover className="relative w-full">
      <Popover.Button
        className={twMerge(
          "w-full flex items-center gap-2 p-2 rounded-lg border border-park-100 dark:border-park-700 bg-white dark:bg-park-900",
          disabled && "opacity-50"
        )}
        disabled={disabled}
      >
        <div
          className="w-4 h-4 min-w-4 min-h-4 rounded-full"
          style={{ backgroundColor: value }}
        />
        <input
          type="text"
          className="w-full bg-transparent"
          value={value}
          disabled={disabled}
          onChange={(event) => onChange(event.target.value)}
        />
      </Popover.Button>
      <Popover.Panel className="absolute w-full p-4 mt-1 z-10 bg-white dark:bg-park-900 rounded-lg border border-park-100 dark:border-park-700 shadow-xl">
        <HexColorPicker className="w-full" color={value} onChange={onChange} />
      </Popover.Panel>
    </Popover>
  );
};

export default ColorInput;
