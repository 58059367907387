import { FC, useRef } from "react";
import { api } from "config/network";
import { useParams } from "react-router-dom";
import { useTokenValue } from "hooks/useToken";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/pro-solid-svg-icons";
import { twMerge } from "tailwind-merge";

type ImageUploadProps = {
  noURL?: boolean;
  className?: string;
  disabled?: boolean;
  value: string;
  onChange: (value: string) => void;
};

const IMAGE_MIME_TYPE = ["image/png", "image/jpeg", "image/gif"];

export const isImageUpload = (type: string): type is "image" =>
  type === "image";

const ImageUpload: FC<ImageUploadProps> = (props) => {
  const { value, className, disabled, noURL, onChange } = props;
  const { guildID } = useParams<{ guildID: string }>();
  const token = useTokenValue();
  const ref = useRef<HTMLInputElement>(null);
  return (
    <div
      className={twMerge(
        "p-3 bg-white dark:bg-park-900 rounded-lg border border-park-100 dark:border-park-700",
        className
      )}
    >
      <input
        ref={ref}
        className="hidden"
        type="file"
        accept={IMAGE_MIME_TYPE.join(",")}
        onChange={(event) => {
          const files = event.target.files;
          if (!files) return;
          const file = files[0];
          if ((IMAGE_MIME_TYPE as string[]).includes(file.type)) {
            if (file.size > 5 * 1024 * 1024) {
              return;
            }
            const reader = new FileReader();
            reader.onload = async (event) => {
              const formData = new FormData();
              formData.append("file", file);
              const { data } = await api.post<{
                hash: string;
              }>(`/guilds/${guildID}/cdn`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${token}`,
                },
              });

              if (noURL) return onChange(data.hash);

              onChange(
                `https://api.points.bot/guilds/${guildID}/cdn/${data.hash}`
              );
            };

            reader.readAsDataURL(file);
          }
        }}
      />
      <div
        className="flex items-center gap-2 cursor-pointer"
        onClick={() => {
          if (disabled) return;
          ref.current?.click();
        }}
      >
        {value ? (
          <img
            src={
              noURL
                ? `https://api.points.bot/guilds/${guildID}/cdn/${value}`
                : value
            }
            alt="Preview"
            style={{
              maxWidth: "25px",
              maxHeight: "25px",
              borderRadius: "50%",
            }}
          />
        ) : (
          <FontAwesomeIcon icon={faImage} />
        )}
        <p className="text-sm">Upload Image</p>
      </div>
    </div>
  );
};

export default ImageUpload;
