import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { useAuthState } from "hooks/useAuthState";
import { parseAvatar } from "utils";
import { Menu } from "@headlessui/react";

const premiumTier = ["", "Basic", "Plus", "Ultra"];

const Dropdown = () => {
  const { data: auth } = useAuthState();
  const navigate = useNavigate();
  if (!!auth) {
    return (
      <Menu as="div" className="relative flex items-center text-left z-20">
        <Menu.Button>
          {auth?.avatar ? (
            <img
              className="w-8 h-8 rounded-full cursor-pointer hover:shadow-xl"
              src={parseAvatar(auth?._id, auth?.avatar, 32)}
            />
          ) : (
            <div className="w-8 h-8 rounded-full bg-park-500 cursor-pointer hover:shadow-xl flex items-center justify-center">
              {auth?.username?.at(0) ?? ""}
            </div>
          )}
        </Menu.Button>
        <Menu.Items className="absolute right-0 top-8 mt-2 w-48 p-2 origin-top-right bg-white dark:bg-park-800 border border-park-50 dark:border-park-700 rounded-xl shadow-2xl focus:outline-none">
          <div className="flex flex-col items-start">
            {!!auth?.premium?.tier ? (
              <p className="uppercase ml-2 mt-1 text-[10px] tracking-wide opacity-50">
                Premium {premiumTier[auth?.premium?.tier ?? 0]}
              </p>
            ) : null}
            <Menu.Item>
              <button
                className="text-sm font-light py-2 px-2 w-full text-left rounded-lg hover:bg-park-50 hover:dark:bg-park-700"
                onClick={() => navigate("/guilds")}
              >
                Servers
              </button>
            </Menu.Item>
            <div className="w-full h-[1px] my-1 bg-park-100 dark:bg-park-700" />
            {!!auth.permission && (
              <>
                <p className="uppercase ml-2 mt-1 text-[10px] tracking-wide opacity-50">
                  {auth.permission}
                </p>
                <Menu.Item>
                  <button
                    className="text-sm font-light py-2 px-2 w-full text-left rounded-lg hover:bg-park-50 hover:dark:bg-park-700"
                    onClick={() => navigate("/admin")}
                  >
                    Admin
                  </button>
                </Menu.Item>
                <div className="w-full h-[1px] my-1 bg-park-100 dark:bg-park-700" />
              </>
            )}
            <Menu.Item>
              <button
                className="font-light text-red-500 text-sm py-2 px-2 w-full text-left rounded-lg hover:bg-red-50 hover:dark:bg-red-500/10"
                onClick={() => {
                  localStorage.removeItem("points_token");
                  navigate("/");
                }}
              >
                Logout
              </button>
            </Menu.Item>
          </div>
        </Menu.Items>
      </Menu>
    );
  }
  return (
    <a
      style={{
        color: "#00a7e3",
      }}
      href={`${
        import.meta.env.VITE_API_URL || "http://localhost:3000"
      }/auth/login`}
    >
      Login
    </a>
  );
};

const Navbar = () => {
  const { data: auth } = useAuthState();

  return (
    <>
      <div className="h-10 border-none bg-transparent">
        <div className="container mx-auto h-full w-full py-8 flex items-center justify-between">
          <div className="flex items-center justify-between w-full mr-6">
            <div className="flex items-center gap-4">
              <Link to="/">
                <img
                  draggable="false"
                  src={`/images/logo/${
                    !!auth?.premium?.tier ? "PremiumLarge" : "DefaultLarge"
                  }.svg`}
                  className="w-8 h-8"
                  alt="Points"
                />
              </Link>
              <Link to="/" className="text-sm opacity-50">
                Home
              </Link>
              <Link to="/commands" className="text-sm text-dimmed  opacity-50">
                Commands
              </Link>
              <Link to="/docs" className="text-sm text-dimmed  opacity-50">
                Docs
              </Link>
            </div>
            <div className="flex items-center">
              <Link to="/discord">
                <FontAwesomeIcon icon={faDiscord} />
              </Link>
            </div>
          </div>
          <Dropdown />
        </div>
      </div>
    </>
  );
};

export default Navbar;
