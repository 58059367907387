import Empty from "domain/guild/Empty";
import { useParams } from "react-router-dom";
import Member from "domain/guild/leaderboard/Member";
import Balance from "domain/guild/leaderboard/Balance";
import Navbar from "components/Navbar";
import GuildHeader from "domain/guild/GuildHeader";
import Body from "components/Body";
import Footer from "components/Footer";
import { useAuthState } from "hooks/useAuthState";
import { useGuild } from "hooks/useGuild";
import { useCurrentMember } from "hooks/useCurrentMember";
import { useLeaderboard } from "hooks/useLeaderboard";
import { selectedMemberAtom } from "stores";
import { useSetAtom } from "jotai";
import { useState } from "react";
import Pagination from "components/Pagination";

const Leaderboard = () => {
  const { guildID } = useParams<{ guildID: string }>();

  const { data: auth, isLoading: authLoading } = useAuthState();
  const { data: guild, isLoading: guildLoading } = useGuild(guildID);
  const { data: currentMember, isLoading: currentMemberLoading } =
    useCurrentMember(guildID);

  const [currentPage, setCurrentPage] = useState(1);
  const { data: leaderboard, isLoading: leaderboardLoading } = useLeaderboard(
    guildID,
    currentPage
  );

  const isLoading =
    authLoading || guildLoading || currentMemberLoading || leaderboardLoading;

  const setSelectedMember = useSetAtom(selectedMemberAtom);

  return (
    <>
      <Navbar />
      <Body>
        <GuildHeader
          guild={guild}
          tab="leaderboard"
          permission={currentMember?.permission}
        />
        {isLoading ? (
          <div className="rounded-xl overflow-hidden shadow-xl bg-white dark:bg-park-900 border border-park-100 dark:border-park-700">
            <div className="flex flex-col gap-4 p-6">
              {[...Array(25)].map((_, i) => (
                <div className="h-10 w-full bg-park-50 dark:bg-park-800 rounded-lg animate-pulse" />
              ))}
            </div>
          </div>
        ) : (
          <>
            {!leaderboard ||
            (leaderboard.balances?.length <= 0 && leaderboard.count == 0) ? (
              <Empty type="points" />
            ) : (
              <>
                <div className="rounded-xl p-6 gap-4 flex flex-col overflow-hidden shadow-xl bg-white dark:bg-park-900 border border-park-100 dark:border-park-700">
                  {leaderboard.balances.map((balance, index) => {
                    const position = index + 1 + (currentPage - 1) * 25;
                    return (
                      <Balance
                        setSelectedMember={(id: string) =>
                          setSelectedMember(id)
                        }
                        key={balance.userID}
                        num={position}
                        id={auth?._id}
                        userID={balance.userID}
                        currency={guild?.currency}
                        balance={balance.balance}
                        username={
                          balance.user
                            ? `${balance.user?.username}#${balance.user?.discriminator}`
                            : "Unknown User"
                        }
                      />
                    );
                  })}
                </div>
                <Pagination
                  total={leaderboard.count}
                  value={currentPage}
                  onChange={(value) => setCurrentPage(value)}
                />
              </>
            )}
          </>
        )}
      </Body>
      <Footer />
      <Member />
    </>
  );
};

export default Leaderboard;
