import { useCallback, useMemo } from "react";

export const useTokenValue = (): string | null => {
  return useMemo(() => {
    const token = localStorage.getItem("points_token");
    if (!token) return null;

    return JSON.parse(token);
  }, []);
};

export const useSetToken = () => {
  return useCallback((token: string) => {
    localStorage.setItem("points_token", JSON.stringify(token));
  }, []);
};

export const useToken = () => {
  const token = useTokenValue();
  const setToken = useSetToken();

  return [token, setToken];
};
