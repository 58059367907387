import Modal from "components/Modal";
import { FC } from "react";

type ResetPromptProps = {
  type: "all" | "balances" | "backpacks" | "bank";
  onClick: (type: "all" | "balances" | "backpacks" | "bank" | "cancel") => void;
};

const ResetPrompt: FC<ResetPromptProps> = (props) => {
  const { type, onClick } = props;
  return (
    <Modal
      open
      onClose={() => onClick("cancel")}
      title={
        type === "all"
          ? "Reset to Default"
          : type === "balances"
            ? "Reset Server Points"
            : type === "backpacks"
              ? "Reset Server Backpacks"
              : "Reset Server Bank"
      }
      description="You will not be able to retrive the data if you procced."
    >
      <div className="bg-gray-50 dark:bg-park-900 flex gap-2 px-6 py-4">
        <button
          className="inline-flex justify-center rounded-lg text-white bg-red-400 hover:bg-red-500 px-3 py-2 text-sm font-semibold shadow-sm w-auto"
          onClick={() => onClick(type)}
        >
          {type === "all"
            ? "Reset to Default"
            : type === "balances"
              ? "Reset Server Points"
              : type === "backpacks"
                ? "Reset Server Backpacks"
                : "Reset Server Bank"}
        </button>
        <button
          className="inline-flex ml-auto justify-center text-white rounded-lg bg-park-600 hover:bg-park-700 px-3 py-2 text-sm font-semibold shadow-sm  w-auto"
          onClick={() => onClick("cancel")}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default ResetPrompt;
