const commands = {
  Points: [
    {
      command: "leaderboard",
      description:
        "Shows the ranking of members based on who has the most points in your server. The command will display the top 10 members and a link to the full leaderboard for your server.",
      usage: "",
      permissions: ["Send Messages", "Embed Links"],
    },
    {
      command: "pay",
      description:
        "Allows members to pay each other in points. If the member does not have enough points the transaction will not go through.",
      usage: "amount: <number> user: <text>",
      permissions: ["Send Messages", "Embed Links"],
    },
    {
      command: "points",
      description:
        "Displays the number of points a specific member has. When run without specifying the member it will display the person who ran the command’s points.",
      usage: "user: [text]",
      permissions: ["Send Messages", "Embed Links"],
    },
  ],
  Items: [
    {
      command: "backpack",
      description:
        "Allows anyone to see what they or other members currently own from the shop.",
      usage: "user: [text]",
      permissions: ["Send Messages", "Embed Links"],
    },
    {
      command: "shop",
      description:
        "Proved you have enough points this command will allow you to buy a specific item from the shop without using the shop interface. If no item is specified this will open the shop.",
      usage: "page: [number]",
      permissions: [
        "Send Messages",
        "Embed Links",
        "Add Reactions",
        "Manage Roles",
        "Manage Messages",
      ],
    },
    {
      command: "gift",
      description:
        "Allows anyone to gift members specific items from their backpack.",
      usage: "user: <text> name: <text>",
      permissions: ["Send Messages", "Embed Links"],
    },
    {
      command: "refund",
      description:
        "Allows members to refund items in their backpack. This will remove any roles associated with the item as well.",
      usage: "name: <text>",
      permissions: ["Send Messages", "Embed Links"],
    },
  ],
  Games: [
    {
      command: "coinflip",
      description:
        "Enter an amount of points that is less than or equal to your balance and you have the potential to win that amount or lose that amount depending on the coin flip’s result.",
      usage: "amount: <number> side: <heads|tails>",
      permissions: ["Send Messages", "Embed Links"],
    },
    {
      command: "dice",
      description:
        "Choose an amount of points less than or equal to your balance an a number from 1-6. Roll the dice and if it lands on your number you gain double the amount otherwise you lose it.",
      usage: "amount: <number> roll: <1-6>",
      permissions: ["Send Messages", "Embed Links"],
    },
    {
      command: "rob",
      description:
        "Attempt to rob a member for up to 200 points. Beware as you can lose those points yourself.",
      usage: "user: <text>",
      permissions: ["Send Messages", "Embed Links"],
    },
  ],

  "Manage Points": [
    {
      command: "add",
      description:
        "Allows for moderators to give members or roles points. This can be useful for giving out points as rewards for various tasks.",
      usage: "amount: <amount> user: [text]",
      permissions: ["Send Messages", "Embed Links"],
    },
    {
      command: "set",
      description:
        "Moderators can set the amount of points a specific member or role has.",
      usage: "amount: <amount> user: [text]",
      permissions: ["Send Messages", "Embed Links"],
    },
    {
      command: "subtract",
      description:
        "The opposite of adding points, this allows moderators to subtract points from members or roles.",
      usage: "amount: <amount> user: [text]",
      permissions: ["Send Messages", "Embed Links"],
    },
  ],

  Rewards: [
    {
      command: "daily",
      description:
        "Given the daily command is enabled in settings this command will award points once per day. None Points Premium members can vote for double points.",
      usage: "",
      permissions: ["Send Messages", "Embed Links"],
    },
    {
      command: "monthly",
      description:
        "Given the monthly command is enabled in settings this command will award points once per month.",
      usage: "",
      permissions: ["Send Messages", "Embed Links"],
      premium: true,
    },
    {
      command: "weekly",
      description:
        "Given the weekly command is enabled in settings this command will award points once per week.",
      usage: "",
      permissions: ["Send Messages", "Embed Links"],
      premium: true,
    },
    {
      command: "yearly",
      description:
        "Given the yearly command is enabled in settings this command will award points once per year.",
      usage: "",
      permissions: ["Send Messages", "Embed Links"],
      premium: true,
    },
  ],
  Bank: [
    {
      command: "bank",
      description: "Display's the number of points the server has.",
      usage: "",
      permissions: ["Send Messages", "Embed Links"],
    },
    {
      command: "tax",
      description: "Allows server moderators to tax balance of members.",
      usage: "user: [text]",
      permissions: ["Send Messages", "Embed Links"],
    },
  ],
};

export default commands;
