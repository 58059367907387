import { FC } from "react";
import { Link } from "react-router-dom";
import { APIUserGuild } from "types/points";

type GuildProps = APIUserGuild & {};

const Guild: FC<GuildProps> = (props) => {
  const { _id, name, icon, invited } = props;

  return (
    <Link
      className="rounded-xl overflow-hidden shadow-xl bg-white dark:bg-park-900 border border-park-100 dark:border-park-700 transition-colors duration-300 ease-in-out hover:bg-park-50 dark:hover:bg-park-800 hover:border-park-200 dark:hover:border-park-700 p-4"
      to={invited ? `/guilds/${_id}` : `/invite/${_id}`}
    >
      <div className="overflow-hidden flex">
        {icon ? (
          <img
            className="w-16 h-16 rounded-lg object-cover object-center"
            draggable="false"
            src={
              icon
                ? `https://cdn.discordapp.com/icons/${_id}/${icon}.png`
                : undefined
            }
            alt={name}
          />
        ) : (
          <div className="w-16 h-16 flex text-xl font-bold items-center justify-center rounded-lg bg-park-200 dark:bg-park-500">
            {name?.at(0) ?? ""}
          </div>
        )}
        <div className="overflow-hidden flex-1 ml-4 items-center flex">
          <p className="truncate text-lg opacity-70">{name}</p>
        </div>
      </div>
    </Link>
  );
};

export default Guild;
