import { FC } from "react";
import { Switch } from "@headlessui/react";
import { twMerge } from "tailwind-merge";

type ToggleProps = {
  id: string;
  className?: string;
  checked?: boolean;
  onChange: (value: boolean) => void;
};

export const isToggle = (type: string): type is "switch" => type === "switch";

const Toggle: FC<ToggleProps> = (props) => {
  const { checked, className, onChange } = props;

  return (
    <Switch
      checked={checked || false}
      onChange={(event) => onChange(!!event)}
      className={twMerge(
        "relative inline-flex h-6 w-11 items-center rounded-full",
        checked ? "bg-brand-500" : "bg-park-400",
        className
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        className={`${
          checked ? "translate-x-6" : "translate-x-1"
        } inline-block w-4 h-4 transform bg-white rounded-full`}
      />
    </Switch>
  );
};

export default Toggle;
