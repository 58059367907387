const docs = {
  "01-bot-setup": {
    title: "Bot Setup",
    files: {
      "01-getting-started": {
        title: "Getting Started",
        url: "/docs/01-bot-setup/01-getting-started",
        hosted_url: "/hosted-docs/01-bot-setup/01-getting-started.md",
      },
      "02-basic-setup": {
        title: "Basic Setup",
        url: "/docs/01-bot-setup/02-basic-setup",
        hosted_url: "/hosted-docs/01-bot-setup/02-basic-setup.md",
      },
      "03-advanced-setup": {
        title: "Advanced Setup",
        url: "/docs/01-bot-setup/03-advanced-setup",
        hosted_url: "/hosted-docs/01-bot-setup/03-advanced-setup.md",
      },
      "04-premium-setup": {
        title: "Premium Setup",
        url: "/docs/01-bot-setup/04-premium-setup",
        hosted_url: "/hosted-docs/01-bot-setup/04-premium-setup.md",
      },
    },
  },
  "02-faq": {
    title: "FAQ",
    files: {
      "01-general-questions": {
        title: "General Questions",
        url: "/docs/02-faq/01-general-questions",
        hosted_url: "/hosted-docs/02-faq/01-general-questions.md",
      },
      "02-support-questions": {
        title: "Support Questions",
        url: "/docs/02-faq/02-support-questions",
        hosted_url: "/hosted-docs/02-faq/02-support-questions.md",
      },
    },
  },
};

export default docs;
