import { FC, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

type BodyProps = {
  children: ReactNode;
  className?: string;
};

const Body: FC<BodyProps> = (props) => {
  const { children, className } = props;

  return (
    <div
      style={{
        minHeight: "calc(100% - 70px - 144px)",
      }}
      className={twMerge("container mx-auto py-8", className)}
    >
      {children}
    </div>
  );
};

export default Body;
