import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import { FC } from "react";
import { editItemPromptAtom } from "stores";
import { useSetAtom } from "jotai";
import { APIItem } from "types/points";

type EditProps = {
  item: APIItem;
};

const EditButton: FC<EditProps> = (props) => {
  const { item } = props;

  const setEditItemPrompt = useSetAtom(editItemPromptAtom);
  return (
    <button
      className="w-8 h-8 rounded-full hover:bg-park-500/50 hover:text-white  font-semibold flex items-center justify-center"
      onClick={() => {
        setEditItemPrompt(item);
      }}
    >
      <FontAwesomeIcon icon={faPencil} />
    </button>
  );
};

export default EditButton;
