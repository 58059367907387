import { FC } from "react";
import { SettingField } from "config/settings";
import Toggle, { isToggle } from "./Toggle";

type Value = any;

type SettingTableRowProps = SettingField & {
  value: Value;
  onChange: (value: Value) => void;
};

const SettingTableRow: FC<SettingTableRowProps> = ({
  name,
  id,
  type,
  value,
  description,
  onChange,
}) => {
  return (
    <div className="flex justify-between w-full">
      <div>
        <p className="font-medium">
          <span>/</span>
          {name?.toLocaleLowerCase()}
        </p>
        <p className="opacity-60">{description}</p>
      </div>
      <div>
        {!!isToggle(type) && (
          <Toggle
            className="ml-auto"
            id={id}
            checked={value}
            onChange={onChange}
          />
        )}
      </div>
    </div>
  );
};

export default SettingTableRow;
