import { FC } from "react";
import { useTokenValue } from "hooks/useToken";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "config/network";

type RecoverProps = {
  guildID: string;
  itemID: string;
  memberID?: string;
  cost?: number;
};

const RefundButton: FC<RecoverProps> = (props) => {
  const { guildID, memberID, itemID } = props;
  const token = useTokenValue();
  const queryClient = useQueryClient();
  const refundItem = useMutation({
    mutationFn: async ({
      guildID,
      itemID,
    }: {
      guildID: string;
      itemID: string;
    }) => {
      if (!token) return;
      const res = await api.post(
        `/guilds/${guildID}/shop/${itemID}`,
        undefined,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res.data;
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["currentMember", guildID],
      });
      queryClient.invalidateQueries({
        queryKey: ["shop", guildID],
      });
    },
  });

  return (
    <button
      type="button"
      className="w-full rounded-lg bg-park-400 dark:bg-park-500 text-white font-semibold py-2 hover:bg-park-600 disabled:bg-park-700 disabled:cursor-not-allowed"
      onClick={() => {
        if (memberID) {
          refundItem.mutate({ guildID, itemID });
        }
      }}
    >
      Refund
    </button>
  );
};
export default RefundButton;
