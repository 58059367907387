import { useParams } from "react-router-dom";
import { useAtom } from "jotai";
import { deleteItemPromptAtom } from "stores";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "config/network";
import { useTokenValue } from "hooks/useToken";
import Modal from "components/Modal";

const ItemDeletionModal = () => {
  const { guildID } = useParams<{ guildID: string }>();
  const [deleteItemPrompt, setDeleteItemPrompt] = useAtom(deleteItemPromptAtom);
  const token = useTokenValue();
  const queryClient = useQueryClient();
  const deleteItem = useMutation({
    mutationFn: async ({
      guildID,
      itemID,
    }: {
      guildID: string;
      itemID: string;
    }) => {
      if (!token) return;
      const res = await api.delete(`/guilds/${guildID}/shop/${itemID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res.data;
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["shop", guildID],
      });
    },
  });

  const hideItem = useMutation({
    mutationFn: async ({
      guildID,
      itemID,
    }: {
      guildID: string;
      itemID: string;
    }) => {
      if (!token) return;
      const res = await api.post(
        `/guilds/${guildID}/shop/${itemID}/hide`,
        undefined,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res.data;
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["shop", guildID],
      });
    },
  });

  return (
    <Modal
      onClose={() => setDeleteItemPrompt(undefined)}
      open={!!deleteItemPrompt}
      title="Delete Confirmation"
      description="Would you like to delete or hide the item?"
    >
      <div className="bg-gray-50 dark:bg-park-900 flex gap-2 px-6 py-4">
        <button
          type="button"
          className="inline-flex ml-auto justify-center rounded-lg text-white bg-park-600 hover:bg-park-700 px-3 py-2 text-sm font-semibold shadow-sm  w-auto"
          onClick={() => {
            if (!deleteItemPrompt || !guildID) return;
            hideItem.mutate({
              guildID,
              itemID: deleteItemPrompt,
            });
            setDeleteItemPrompt(undefined);
          }}
        >
          Hide
        </button>
        <button
          type="button"
          className="inline-flex justify-center rounded-lg  text-white bg-red-400 hover:bg-red-500 px-3 py-2 text-sm font-semibold shadow-sm w-auto"
          onClick={() => {
            if (!deleteItemPrompt || !guildID) return;
            deleteItem.mutate({
              guildID,
              itemID: deleteItemPrompt,
            });
            setDeleteItemPrompt(undefined);
          }}
        >
          Delete
        </button>
      </div>
    </Modal>
  );
};

export default ItemDeletionModal;
