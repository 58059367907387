import { customizationGroups } from "config/settings";
import SettingPage from "domain/guild/settings/SettingPage";
import useSettings from "hooks/useSettings";
import SettingGroup from "domain/guild/settings/SettingGroup";
import SettingCard from "domain/guild/settings/SettingCard";
import { GuildPremiumTier } from "types/points";
import { useCallback, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRemove } from "@fortawesome/pro-solid-svg-icons";
import { api } from "config/network";
import { useAuthState } from "hooks/useAuthState";
import { useGuild } from "hooks/useGuild";
import { GuildParams } from "types/routes";
import { Link, useParams } from "react-router-dom";
import { defaultSettings } from "config/defaults";
import ResetPrompt from "domain/guild/settings/ResetPrompt";
import Modal from "components/Modal";
import { useQueryClient } from "@tanstack/react-query";

type ManagePremiumModalProps = {
  guildID?: string;
  premiumTier?: GuildPremiumTier;
};

const ManagePremiumModal = (props: ManagePremiumModalProps) => {
  const { guildID, premiumTier } = props;
  const { data: auth } = useAuthState();

  const toggleGuild = useCallback(async (id: string) => {
    if (!id) return;
    const token = localStorage.getItem("points_token");
    if (!token) return;
    await api.post(`/guilds/${id}/settings/premium`, undefined, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });

    window.location.reload();
  }, []);

  if (premiumTier === GuildPremiumTier.BASIC) {
    return (
      <div>
        This servers Premium Basic is enabled by default because you own this
        server...
      </div>
    );
  }

  const isInGuilds = auth?.premium?.guilds?.find((g) => g === guildID);

  return (
    <div className="p-6 pt-0">
      <h5 className="text-lg font-bold mb-4">Active Servers</h5>
      <div className="my-4">
        {auth?.premium?.guilds?.map((guildID) => {
          const guild = auth?.guilds?.find((g) => g._id === guildID);
          return (
            <div
              key={guildID}
              className="rounded-xl p-4 mb-4 flex items-center justify-between bg-dark"
            >
              <p>{guild?.name}</p>

              <button
                className="p-2 bg-red-500 text-white font-bold rounded-lg"
                onClick={() => toggleGuild(guildID)}
              >
                <FontAwesomeIcon icon={faRemove} />
              </button>
            </div>
          );
        })}
      </div>

      {!isInGuilds && (
        <div className="mt-4">
          <h6 className="opacity-70 mb-4">
            Looks like we couldn't find this server in your active servers. You
            can still disable it here...
          </h6>
          <button
            className="p-2 px-4 bg-red-500 text-white font-bold rounded-lg"
            onClick={() => toggleGuild(guildID!)}
          >
            Disable Server Premium
          </button>
        </div>
      )}
    </div>
  );
};

const PremiumCard = () => {
  const { guildID } = useParams<GuildParams>();
  const { data: auth } = useAuthState();
  const { data: guild } = useGuild(guildID);

  const [openPremiumManage, setOpenPremiumManage] = useState(false);

  const tier = guild?.premium?.tier;
  const userID = guild?.premium?.userID;

  const togglePremium = useCallback(async () => {
    if (!guild?._id) return;
    const token = localStorage.getItem("points_token");
    if (!token) return;
    await api.post(`/guilds/${guild._id}/settings/premium`, undefined, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });

    window.location.reload();
  }, [guild]);

  if (!tier && !!auth?.premium?.tier) {
    if (auth?.premium?.tier === 1) {
      return (
        <p className="opacity-70">
          You need to own the server to use premium customizations. Subscribe to
          a higher tier to unlock customizations in servers you don't own.
        </p>
      );
    }
    if (auth?.premium?.availableGuildSlots === 0) {
      return (
        <>
          <Modal
            open={openPremiumManage}
            onClose={() => setOpenPremiumManage(false)}
            title="Manage Premium"
          >
            <ManagePremiumModal guildID={guild?._id} premiumTier={tier} />
          </Modal>
          <div>
            <p className="opacity-70">
              You have no available server slots. Consider upgrading your
              subscription to add more.
            </p>
            <button
              className="p-2 px-4 bg-premium-500 text-white font-bold rounded-lg"
              onClick={() => setOpenPremiumManage(true)}
            >
              Manage Premium
            </button>
          </div>
        </>
      );
    }
    return (
      <div>
        <div className="flex items-center gap-4">
          <h4>Premium</h4>
          <div className="bg-premium-500 text-xs text-white font-bold rounded-full p-1 px-2">
            {auth?.premium?.tier === 2 ? "Plus" : "Ultra"}
          </div>
        </div>
        <div className="flex items-center justify-between mt-1">
          <p className="opacity-70">
            You can enable Premium{" "}
            {auth?.premium?.tier === 2 ? "Plus" : "Ultra"} to allow all server
            members to use Premium for this server.
          </p>
          <button
            className="p-2 px-4 bg-premium-500 text-white font-bold rounded-lg"
            onClick={() => togglePremium()}
          >
            Enable Premium
          </button>
        </div>
      </div>
    );
  }

  if (tier === GuildPremiumTier.PLUS && userID === auth?._id) {
    return (
      <>
        <Modal
          open={openPremiumManage}
          onClose={() => setOpenPremiumManage(false)}
          title="Manage Premium"
        >
          <ManagePremiumModal guildID={guild?._id} premiumTier={tier} />
        </Modal>
        <div>
          <div className="flex items-center gap-2">
            <h4 className="font-bold">Premium</h4>
            <div className="bg-premium-500 text-xs text-white font-bold rounded-full p-1 px-2">
              Plus
            </div>
          </div>
          <div className="flex items-center justify-between mt-1">
            <p className="opacity-70">
              You have enabled Premium Plus for this server.
            </p>
            <button
              className="p-2 px-4 bg-premium-500 text-white font-bold rounded-lg"
              onClick={() => setOpenPremiumManage(true)}
            >
              Manage Premium
            </button>
          </div>
        </div>
      </>
    );
  }

  if (tier === GuildPremiumTier.BASIC && guild?.ownerID === auth?._id) {
    const canAddMorePremium =
      (auth?.premium?.tier || 0) > 1 &&
      (auth?.premium?.availableGuildSlots || 0) > 0;
    return (
      <div>
        <div className="flex items-center gap-4">
          <h4 className="font-bold">Premium</h4>
          <div className="bg-premium-500 text-xs text-white font-bold rounded-full p-1 px-2">
            Basic
          </div>
        </div>
        <div className="mt-1">
          <div className="opacity-70">
            Premium Basic was automatically enabled for this server as part of
            your subscription.{" "}
            {!canAddMorePremium && (
              <strong>
                Only you can use Premium Basic features.{" "}
                <a href="https://www.patreon.com/join/PointsCity/checkout?rid=3790562">
                  Upgrade to add more Premium Plus server slots.
                </a>
              </strong>
            )}
          </div>
          {canAddMorePremium && (
            <div className="mt-4 flex items-center justify-between">
              <p className="opacity-70">
                You can enable Premium Plus to allow all server members to use
                Premium for this server.
              </p>
              <button
                className="p-2 bg-brand-500 text-white rounded-lg"
                onClick={() => togglePremium()}
              >
                Enable Premium
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }

  if (!!tier && userID !== auth?._id) {
    return <div>{userID} is premium</div>;
  }

  return (
    <div>
      <h4 className="font-bold">Get Premium</h4>
      <p className="opacity-70">
        Looks like you don't have Premium. Premium enables more customization to
        give you more control over your server setup. Own Premium but
        subscription hasn't actived? Contact us{" "}
        <a className="text-brand-500" href="https://discord.gg/innatical">
          discord.gg/innatical
        </a>
      </p>
    </div>
  );
};

const Customization = () => {
  const {
    guild,
    loading,
    saving,
    settings,
    handleSave,
    addError,
    getSettingValue,
    setSettings,
    setSettingValue,
    removeError,
    setSaving,
  } = useSettings();
  const queryClient = useQueryClient();
  const [reset, setReset] = useState<
    "bank" | "balances" | "backpacks" | "all"
  >();

  const handleReset = useCallback(async () => {
    if (!guild) return;
    const token = localStorage.getItem("points_token");
    if (!token) return;
    await api.post(
      `/guilds/${guild?._id}/settings/reset`,
      {
        type: reset,
      },
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      }
    );
    setSettings(defaultSettings);
    setReset(undefined);
  }, [guild, reset, setSettings]);

  const handleResetAPIKey = useCallback(async () => {
    if (!guild) return;
    const token = localStorage.getItem("points_token");
    if (!token) return;
    await api.post(
      `/guilds/${guild?._id}/settings/reset-api-key`,
      {},
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      }
    );
    queryClient.invalidateQueries({
      queryKey: ["settings", guild?._id],
    });
  }, [guild, queryClient]);

  const hasOldCommands = useMemo(() => {
    return Object.values(settings?.commands ?? {})
      .map((value) => {
        if (!value) return false;
        if (value.enabled === false) return true;
        return false;
      })
      .includes(true);
  }, [settings?.commands]);

  return (
    <>
      {reset && <ResetPrompt type={reset} onClick={handleReset} />}
      <SettingPage
        guild={guild}
        loading={loading}
        saving={saving}
        tab="customization"
        groups={customizationGroups}
        onSave={handleSave}
      >
        <div className="rounded-xl p-4 overflow-hidden shadow-xl bg-white dark:bg-park-900 border border-park-100 dark:border-park-700">
          <p className="font-bold">
            Need more control over who can use those commands?
          </p>
          <p className="mb-2 opacity-70">
            Discord now has a built in permissions system allowing you to go
            indepth with who can use what commands. You can setup the command
            permissions under{" "}
            <strong>Server Settings → Integrations → Points.</strong>
          </p>
          {hasOldCommands ? (
            <>
              <p className="mb-2 opacity-70">
                You have old command permissions setup. You can manage them
                here. This will be deprecated soon so please move to the above
                soon.
              </p>
              <Link to={`/guilds/${guild?._id}/settings/commands`}>
                <button className="w-full bg-brand-500 text-white p-2 rounded-lg">
                  Manage Command Permissions
                </button>
              </Link>
            </>
          ) : (
            <a
              className="text-brand-500 hover:text-brand-600 transition-colors duration-300 ease-in-out"
              href="https://discord.com/blog/slash-commands-permissions-discord-apps-bots"
            >
              Learn more
            </a>
          )}
        </div>
        <div className="rounded-xl mt-4 p-4 overflow-hidden shadow-xl bg-white dark:bg-park-900 border border-park-100 dark:border-park-700">
          <PremiumCard />
        </div>
        {customizationGroups.map((group) => (
          <SettingGroup
            {...group}
            key={group.id}
            premiumTier={guild?.premium?.tier}
            tab="customization"
          >
            {({ id, setting, premiumTier }) => {
              const settingValue = getSettingValue(id);
              return (
                <SettingCard
                  {...setting}
                  id={id}
                  value={settingValue}
                  onError={() => addError(id)}
                  items={
                    setting.type === "role" ? guild?.roles : guild?.channels
                  }
                  premiumTier={premiumTier}
                  onChange={(value, changedID) => {
                    if (changedID) {
                      setSettingValue(changedID, value);
                      removeError(changedID);
                      setSaving(true);
                    } else {
                      setSettingValue(id, value);
                      removeError(id);
                      setSaving(true);
                    }
                  }}
                />
              );
            }}
          </SettingGroup>
        ))}
        <hr className="my-8 border border-park-100 dark:border-park-800" />
        <div className="rounded-xl relative p-4 shadow-xl bg-white dark:bg-park-900 border border-premium-500 dark:border-premium-500">
          <h5 className="font-bold">
            API Key
            <div className="uppercase tracking-wider font-semibold flex items-center justify-center rounded-full px-2 py-1 text-xs absolute -top-2.5 -right-2.5 shadow-xl bg-premium-500 text-white">
              Ultra
            </div>
          </h5>
          <p className="opacity-70 mb-2">
            You can use the Points API to interact with your server. You can
            generate an API key here. This feature is currently in closed beta.
          </p>
          <div className="flex gap-2">
            <input
              type="text"
              className="w-full p-2 rounded-lg border border-park-100 dark:border-park-700 bg-park-50 dark:bg-park-900"
              placeholder="No API Key Set"
              readOnly
              value={settings?.apiKey}
            />
            <button
              className="p-2 px-4 text-nowrap rounded-lg bg-red-400 disabled:bg-park-600 text-white"
              disabled={!settings?.apiKey}
              onClick={handleResetAPIKey}
            >
              Reset
            </button>
          </div>
        </div>
        <hr className="my-8 border border-park-100 dark:border-park-800" />
        <div className="rounded-xl p-4 overflow-hidden shadow-xl bg-red-50/10 dark:bg-red-900/10 border border-red-100 dark:border-red-700">
          <h5 className="mb-2 font-bold">Danger Zone</h5>
          <div className="flex gap-4">
            <button
              className="w-full p-2 rounded-lg bg-purple-500 text-white"
              onClick={() => setReset("bank")}
            >
              Reset Bank
            </button>
            <button
              className="w-full p-2 rounded-lg bg-teal-500 text-white"
              onClick={() => setReset("backpacks")}
            >
              Reset Backpacks
            </button>
            <button
              className="w-full p-2 rounded-lg bg-orange-500 text-white"
              onClick={() => setReset("balances")}
            >
              Reset Balances
            </button>
            <button
              className="w-full p-2 rounded-lg bg-red-500 text-white"
              onClick={() => setReset("all")}
            >
              Reset to Default
            </button>
          </div>
        </div>
      </SettingPage>
    </>
  );
};

export default Customization;
