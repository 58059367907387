import { FC } from "react";

type SavePromptProps = {
  onSave: () => void;
};

const SavePrompt: FC<SavePromptProps> = (props) => {
  const { onSave } = props;
  return (
    <div className="p-2 bg-park-500 dark:bg-park-700 rounded-xl shadow-2xl text-nowrap fixed bottom-2 left-2 z-50 flex items-center">
      <strong className="mr-1 ml-2">Holy guacamole!</strong> Make sure to save
      your changes!
      <button
        className="px-4 py-2 ml-4 bg-brand-500 rounded-lg hover:bg-brand-600"
        type="button"
        onClick={onSave}
      >
        Save
      </button>
    </div>
  );
};

export default SavePrompt;
