import { useState } from "react";
import ColorInput from "./ColorInput";

type InputProps<T extends "string" | "number" | "color"> = {
  id: string;
  type: T;
  vaildate?: string;
  description?: string;
  error?: string;
  value: T extends "string" ? string : T extends "number" ? number : string;
  disabled?: boolean;
  onChange(value: string | number | boolean): void;
  onError?(): void;
};

export const isInput = (type: string): type is "number" | "string" | "color" =>
  type === "string" || type === "number" || type === "color";

const Input = <T extends "string" | "number" | "color">(
  props: InputProps<T>
) => {
  const {
    id,
    disabled,
    vaildate,
    description,
    value,
    type,
    error,
    onChange,
    onError,
  } = props;
  const [hasError, setHasError] = useState(false);

  switch (type) {
    case "string": {
      return (
        <div>
          {description ? (
            <p className="text-xs mt-1 opacity-70 mb-2">{description}</p>
          ) : null}
          <input
            type={type}
            className="w-full p-2 rounded-lg border border-park-100 dark:border-park-700 bg-white dark:bg-park-900 disabled:opacity-50"
            id={id}
            value={value}
            disabled={disabled}
            onChange={(event) => {
              if (
                !event.target.value ||
                (vaildate && !new RegExp(vaildate).test(event.target.value))
              ) {
                setHasError(true);
                if (onError) onError();
              }
              setHasError(false);
              onChange(event.target.value);
            }}
          />
          {hasError && <small className="text-red-500">{error}</small>}
        </div>
      );
    }
    case "number": {
      return (
        <div>
          {description ? (
            <p className="text-xs mt-1 opacity-70 mb-2">{description}</p>
          ) : null}
          <input
            type="number"
            className="w-full p-2 rounded-lg border border-park-100 dark:border-park-700 bg-white dark:bg-park-900 disabled:opacity-50"
            id={id}
            value={value}
            disabled={disabled}
            onChange={(event) => {
              if (
                !event.target.value ||
                typeof event.target.value !== "number"
              ) {
                setHasError(true);
                if (onError) onError();
              }
              setHasError(false);
              onChange(event.target.value);
            }}
          />
          {hasError && <small className="text-danger">{error}</small>}
        </div>
      );
    }
    case "color": {
      return (
        <div>
          {description ? (
            <p className="text-xs mt-1 opacity-70 mb-2">{description}</p>
          ) : null}
          <ColorInput
            disabled={disabled}
            value={value as string}
            onChange={onChange}
          />
        </div>
      );
    }
    default: {
      return <></>;
    }
  }
};

export default Input;
