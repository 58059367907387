import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FC } from "react";
import { useSetAtom } from "jotai";
import { deleteItemPromptAtom } from "stores";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTokenValue } from "hooks/useToken";
import { api } from "config/network";

type DeleteProps = {
  itemID: string;
  guildID?: string;
  memberID?: string;
  preview?: boolean;
};

const DeleteButton: FC<DeleteProps> = (props) => {
  const { itemID, guildID, memberID, preview } = props;
  const setDeleteItemPrompt = useSetAtom(deleteItemPromptAtom);
  const token = useTokenValue();
  const queryClient = useQueryClient();
  const removeItem = useMutation({
    mutationFn: async ({
      guildID,
      memberID,
      itemID,
    }: {
      guildID: string;
      memberID: string;
      itemID: string;
    }) => {
      if (!token) return;
      const res = await api.delete(
        `/guilds/${guildID}/members/${memberID}/${itemID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res.data;
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["currentMember", guildID],
      });
      queryClient.invalidateQueries({
        queryKey: ["member", guildID, memberID],
      });
    },
  });
  return (
    <button
      className="w-8 h-8 rounded-full bg-red-500/15 text-red-500 hover:bg-red-500 hover:text-white  font-semibold flex items-center justify-center"
      onClick={() => {
        if (!preview) return setDeleteItemPrompt(itemID);
        if (guildID && memberID && itemID) {
          return removeItem.mutate({ guildID, memberID, itemID });
        }
      }}
    >
      <FontAwesomeIcon icon={faTrash} />
    </button>
  );
};

export default DeleteButton;
