import { FC, Fragment, ReactNode, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/pro-solid-svg-icons";
import { twMerge } from "tailwind-merge";

type SlideoverProps = {
  opened: boolean;
  title: string;
  onClose: () => void;
  children: ReactNode;
  className?: string;
};

const Slideover: FC<SlideoverProps> = (props) => {
  const { opened, title, className, onClose, children } = props;

  return (
    <Transition.Root show={opened} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-10 dark:bg-black dark:bg-opacity-50 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-3xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white dark:bg-park-950 py-6 shadow-xl">
                    <div className="px-4 sm:px-6 flex items-center justify-between">
                      <Dialog.Title className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
                        {title}
                      </Dialog.Title>
                      <button
                        type="button"
                        className="relative rounded-md dark:text-gray-300 dark:hover:text-white focus:outline-none"
                        onClick={() => onClose()}
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <FontAwesomeIcon icon={faClose} className="text-xl" />
                      </button>
                    </div>
                    <div
                      className={twMerge(
                        "relative mt-6 flex-1 px-4 sm:px-6",
                        className
                      )}
                    >
                      {children}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Slideover;
