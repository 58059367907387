import { GuildPremiumTier } from "types/points";
import { FC, useMemo } from "react";
import { SubGroup, commandsFields, customizationFields } from "config/settings";
import SettingCardWrapper, { SettingCardFunc } from "./SettingCardWrapper";

type SettingSubGroupProps = SubGroup & {
  premiumTier?: GuildPremiumTier;
  tab: "commands" | "customization" | "system";
  cardFunc: SettingCardFunc;
};

export const SettingSubGroup: FC<SettingSubGroupProps> = (props) => {
  const { id, name, premiumTier, tab, cardFunc } = props;

  const groupSettings = useMemo(() => {
    switch (tab) {
      case "commands":
        return commandsFields.filter((setting) => setting.group === id);
      case "customization":
        return customizationFields.filter((setting) => setting.group === id);
      default:
        return [];
    }
  }, [id, tab]);

  return (
    <div className="mb-6">
      {name ? <p className="mb-2 font-bold">{name}</p> : undefined}
      {tab !== "commands" ? (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {groupSettings.map((setting) => {
              return (
                <SettingCardWrapper
                  {...setting}
                  key={setting.id}
                  premiumTier={premiumTier}
                  cardFunc={cardFunc}
                />
              );
            })}
          </div>
        </>
      ) : (
        <>
          {groupSettings.map((setting) => {
            return (
              <SettingCardWrapper
                {...setting}
                key={setting.id}
                premiumTier={premiumTier}
                cardFunc={cardFunc}
              />
            );
          })}
        </>
      )}
    </div>
  );
};
