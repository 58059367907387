import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useMemo } from "react";
import { twMerge } from "tailwind-merge";

type PaginationProps = {
  total: number;
  value: number;
  onChange: (value: number) => void;
};

const range = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, index) => index + start);
};

const DOTS = "dots";
const boundaries = 1;
const siblings = 1;

const Pagination: FC<PaginationProps> = (props) => {
  const { total, value, onChange } = props;
  const _total = Math.max(Math.trunc(total), 0);

  const pageNumbers = useMemo(() => {
    const totalPageNumbers = siblings * 2 + 3 + boundaries * 2;
    if (totalPageNumbers >= _total) {
      return range(1, _total);
    }
    const leftSiblingIndex = Math.max(value - siblings, boundaries);
    const rightSiblingIndex = Math.min(value + siblings, _total - boundaries);

    const shouldShowLeftDots = leftSiblingIndex > boundaries + 2;
    const shouldShowRightDots = rightSiblingIndex < _total - (boundaries + 1);

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = siblings * 2 + boundaries + 2;
      return [
        ...range(1, leftItemCount),
        DOTS,
        ...range(_total - (boundaries - 1), _total),
      ];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = boundaries + 1 + 2 * siblings;
      return [
        ...range(1, boundaries),
        DOTS,
        ...range(_total - rightItemCount, _total),
      ];
    }

    return [
      ...range(1, boundaries),
      DOTS,
      ...range(leftSiblingIndex, rightSiblingIndex),
      DOTS,
      ...range(_total - boundaries + 1, _total),
    ];
  }, [value, _total]);

  return (
    <div className="flex items-center justify-between mt-4">
      <div className="flex-1 items-center justify-between">
        <div>
          <nav
            className="isolate inline-flex gap-2 -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <a
              href="#"
              className="relative inline-flex items-center justify-center rounded-lg w-8 p-1.5 bg-white dark:bg-park-900 border border-park-100 dark:border-park-700"
            >
              <span className="sr-only">Previous</span>
              <FontAwesomeIcon
                icon={faChevronLeft}
                className="text-sm opacity-50"
              />
            </a>

            {pageNumbers.map((number, index) =>
              number === "dots" ? (
                <a key={`${index}-dots`} href="#">
                  <span className="p-2 text-sm">...</span>
                </a>
              ) : (
                <a key={number}>
                  <button
                    onClick={() =>
                      typeof number === "number" ? onChange(number) : null
                    }
                    className={twMerge(
                      `w-8 p-1.5 text-sm rounded-lg bg-white dark:bg-park-900 border border-park-100 dark:border-park-700`,
                      number === value
                        ? "bg-brand-500 dark:bg-brand-500 border-brand-500 dark:border-brand-500"
                        : ""
                    )}
                  >
                    {number}
                  </button>
                </a>
              )
            )}

            <a
              href="#"
              className="relative inline-flex items-center justify-center rounded-lg w-8 p-1.5 bg-white dark:bg-park-900 border border-park-100 dark:border-park-700"
            >
              <span className="sr-only">Next</span>
              <FontAwesomeIcon
                icon={faChevronRight}
                className="text-sm opacity-50"
              />
            </a>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
