import { FC } from "react";
import { GuildPremiumTier } from "types/points";
import { SettingSubGroup } from "./SettingSubGroup";
import { SettingCardFunc } from "./SettingCardWrapper";
import { canUsePremium } from "utils";
import { Group } from "config/settings";

type SettingGroupProps = Group & {
  premiumTier?: GuildPremiumTier;
  tab: "commands" | "customization" | "system";
  children: SettingCardFunc;
};

const SettingGroup: FC<SettingGroupProps> = ({
  id,
  name,
  sub,
  tab,
  premium,
  premiumTier,
  children,
}) => {
  if (!canUsePremium(premium, premiumTier)) return <></>;
  return (
    <div className="mb-4">
      <h3 className="mt-4 mb-2">{name}</h3>
      {sub ? (
        sub.map((subGroup) => (
          <SettingSubGroup
            key={subGroup.id}
            cardFunc={children}
            tab={tab}
            premiumTier={premiumTier}
            {...subGroup}
          />
        ))
      ) : (
        <SettingSubGroup
          cardFunc={children}
          tab={tab}
          premiumTier={premiumTier}
          id={id}
        />
      )}
    </div>
  );
};

export default SettingGroup;
