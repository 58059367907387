import ItemDeletionModal from "domain/guild/shop/modals/ItemDeletionModal";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import Navbar from "components/Navbar";
import GuildHeader from "domain/guild/GuildHeader";
import Footer from "components/Footer";
import Body from "components/Body";
import ItemCreationModel from "domain/guild/shop/modals/ItemCreationModal";
import ItemEditModal from "domain/guild/shop/modals/ItemEditModal";
import { GuildParams } from "types/routes";
import ItemGrid from "domain/guild/shop/ItemGrid";
import { useAuthState } from "hooks/useAuthState";
import { useGuild } from "hooks/useGuild";
import { useCurrentMember } from "hooks/useCurrentMember";
import { useShop } from "hooks/useShop";
import { useAtomValue } from "jotai";
import { editItemPromptAtom } from "stores";

const Shop = () => {
  const { guildID } = useParams<GuildParams>();

  const { data: auth, isLoading: authLoading } = useAuthState();
  const { data: guild, isLoading: guildLoading } = useGuild(guildID);
  const { data: currentMember, isLoading: currentMemberLoading } =
    useCurrentMember(guildID);
  const { data: shop, isLoading: shopLoading } = useShop(guildID);
  const editItemPrompt = useAtomValue(editItemPromptAtom);

  const loading =
    guildLoading || shopLoading || currentMemberLoading || authLoading;

  const availableItems = useMemo(() => {
    if (!shop) return [];
    return shop.filter((item) => item.available);
  }, [shop]);

  const unavailableItems = useMemo(() => {
    if (!shop) return [];
    return shop.filter((item) => !item.available);
  }, [shop]);

  if (!guildID) return <></>;

  return (
    <>
      <Navbar />
      <Body>
        <ItemCreationModel
          guildID={guildID}
          roles={guild?.roles || []}
          premiumTier={guild?.premium?.tier ? 3 : auth?.premium?.tier}
        />
        {!!editItemPrompt ? (
          <ItemEditModal
            item={editItemPrompt}
            guildID={guildID}
            roles={guild?.roles || []}
            premiumTier={guild?.premium?.tier ? 3 : auth?.premium?.tier}
          />
        ) : null}
        <ItemDeletionModal />
        <GuildHeader
          guild={guild}
          tab="shop"
          permission={currentMember?.permission}
        />

        <ItemGrid
          loading={!!loading}
          items={availableItems}
          roles={guild?.roles || []}
          permission={currentMember?.permission}
          draggable={!!currentMember?.permission}
        />

        {unavailableItems.length > 0 && !!currentMember?.permission && (
          <div className="mt-8">
            <h4 className="mb-4 text-lg font-semibold">Hidden</h4>
            <ItemGrid
              loading={!!loading}
              items={unavailableItems}
              roles={guild?.roles || []}
              permission={currentMember?.permission}
            />
          </div>
        )}
      </Body>
      <Footer />
    </>
  );
};

export default Shop;
