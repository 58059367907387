import Permission from "utils/Permission";
import Empty from "domain/guild/Empty";
import { useMemo } from "react";
import Guild from "domain/guild/Guild";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import Body from "components/Body";
import { useGuilds } from "hooks/useGuilds";

const Guilds = () => {
  const { data: rawGuilds, isLoading: isLoadingGuilds } = useGuilds();

  const guilds = useMemo(() => {
    return (rawGuilds ?? []).filter((g) => {
      const permission = new Permission(g.permissions);
      if (permission.has("manageGuild")) return g;
      else return undefined;
    });
  }, [rawGuilds]);

  const invitedGuilds = useMemo(() => {
    return guilds?.filter((g) => g.invited);
  }, [guilds]);

  const uninvitedGuilds = useMemo(() => {
    return guilds?.filter((g) => !g.invited);
  }, [guilds]);

  return (
    <>
      <Navbar />
      <Body>
        <h1 className="mt-8 text-4xl font-bold mb-4">Servers</h1>
        {isLoadingGuilds ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {[...Array(12)].map((_, i) => (
              <div
                key={i}
                className="h-[168px] w-full bg-park-50 dark:bg-park-900 rounded-xl animate-pulse"
              />
            ))}
          </div>
        ) : (
          <>
            {invitedGuilds && invitedGuilds.length > 0 ? (
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                {invitedGuilds.map((guild) => (
                  <Guild key={guild._id} {...guild} />
                ))}
              </div>
            ) : (
              <Empty />
            )}
            {uninvitedGuilds && uninvitedGuilds.length > 0 ? (
              <>
                <h2 className="mt-8 text-2xl font-bold">Uninvited Servers</h2>
                <p className="mb-4 opacity-50 font-light">
                  You can invite the bot to the following servers.
                </p>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                  {uninvitedGuilds.map((guild) => (
                    <Guild key={guild._id} {...guild} />
                  ))}
                </div>
              </>
            ) : null}
          </>
        )}
      </Body>
      <Footer />
    </>
  );
};

export default Guilds;
